import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GthAuthService } from '@gth-legacy/services';
import { GthUserModel } from '@sentinels/models';
import { UserService } from '@sentinels/services/firebase/user.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PwaNotificationsService {
  private set = false;

  get enabled$() {
    return this.auth.userModel$.pipe(
      map((user) => {
        if (!user) {
          return false;
        }

        const tokens = (user as any).notificationTokens ?? [];
        return tokens.length > 0;
      }),
    );
  }

  constructor(
    private auth: GthAuthService,
    private snackbar: MatSnackBar,
    private afMessaging: AngularFireMessaging,
    private usersService: UserService,
  ) {
    this.listen();
  }

  listen() {
    this.afMessaging.messages.subscribe();
  }

  request(user: GthUserModel, withOverride = false) {
    if (!withOverride && user?.notificationTokens && user.notificationTokens.length > 0) {
      return;
    }
    this.afMessaging.requestToken
      .subscribe(
        async (token) => {
          if (token) await this.onToken(user, token);
        },
        (error) => {
          console.error(error);
        },
      );
  }

  private async onToken(user: GthUserModel, token: string) {
    if (this.set) {
      return;
    }
    console.log('Updating user token');
    const tokens = user?.notificationTokens ? [...user.notificationTokens] : [];
    tokens.push(token);
    user.notificationTokens = tokens;
    await this.usersService.updateUser(user);
    // eslint-disable-next-line max-len
    this.snackbar.open('Notifications enabled. You will now get the latest updates from Gametime Hero');
  }
}
